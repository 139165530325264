import { useEffect, useState } from "react";
import React from "react";
import { shuffle } from "lodash";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import "../App.css";
import "./all.css";
import axiosInstance from "../axioApi";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const [tagsdata, setTagsdata] = useState([]);
  const isAuthenticated = !!localStorage.getItem('access_token');
   useEffect(() => {
    fetch("/react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []);
  //   console.log(tagsdata);

  return (
  <div className="container-footer">
        <footer>
          {/*<div>
            <p>copyright@9faqs.com</p>
          </div>*/}
          <span className="python-tags"> Tags:
          </span>
          {tagsdata[1]?.tags?.map((tag_name, i) => (
            <span className="python-tags"
              key={tag_name.id}
            > 
              {tag_name.name}
              {i < tagsdata[1].tags.length - 1 ? " | " : null}
            </span>
          ))}
         <div></div>
          <br />
        <div className="footer-about">
        <a className="footer-about" href='/about'>About </a>  &nbsp;|
        <a className="footer-about" href='https://dev.pythonfaqs.com/feedback'>&nbsp;  Feedback &nbsp;| </a>
        {isAuthenticated ? (
          <>
            {/* Remove Login from footer */}
          </>
        ) : (
          <Link
            to="/login"
            className={`menu-item`} className="footer-about"
          >
            Login &nbsp; | &nbsp;
          </Link>
        )}
          copyright@pythonfaqs.com
        </div>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/9FAQs" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/9faqs_official/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://x.com/9faqs" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
        </footer>
      </div>
  );
}

export default Footer;
